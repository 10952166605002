* {
  font-family: 'Roboto', sans-serif;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

body {
  background-color: rgb(8, 8, 12);
  color: rgb(182, 195, 251);
  font-size: .8em;
}

.main-container {
  margin: 3% 10%;
}

.emph-txt {
  text-decoration: underline;
}

#mode-dropdown {
  width: 40%;
  text-align: center;
  /* font size that scales with the width of its container */
  font-size: calc(0.5em + 1vw);
}
/* NAVBAR  */

#navbar {
  background-color: rgb(28, 30, 38);
  border-radius: 3px;
  padding: 1%;
}
#logo {
  font-size: 1.3rem;
  font-family: 'Zen Dots', sans-serif;
  
} 
.nav-button {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 1% 3%;
  border-radius: 6%;
  width: fit-content;
  margin: 0 1%;
  transition: background-color  .2s;
  
}

.nav-button:hover, .tweet:hover {
  background-color: rgb(66, 71, 90);
  color: rgb(181, 181, 183);
  cursor: pointer;
}

#authentication {
  justify-content: end;
}

/* MANIFEST  */

#manifest {
  height: 80vh;
}

/* SIDEBAR AND KW OPTIONS*/
#sidebar-wrapper {
  background-color: rgb(28, 30, 38);
  width: 25%;
  height: 95%;
  border-radius: 5px;
  font-size: 1.8vw;
}

#sidebar {
  overflow-y: scroll;
  height: 100%;
  min-height: 400px;
}

#sidebar-header {
  font-weight: bold;
}

#sidebar::-webkit-scrollbar{
  display: none;
}

.kw-options, #sidebar-header {
  margin: 5% 1%;
  padding-top: 1.2%;
  text-align: center;
  border-radius: 3px;
  min-height: 5%;
  transition: background-color .3s;
}
.kw-options:hover {
  background-color: rgb(66, 71, 90);
  cursor: pointer;
  text-decoration: underline;
}

.selected {
  background-color: rgb(66, 71, 90);
  color: white;
}

/* CONTENT  */

#content-wrapper {
  /* width: 75%; */
  width: 100%;
  /* padding: 0 0 0 2%; */
}

#content {
  background-color: rgb(28, 30, 38);
  height: 95%;
  padding: 3%;
  border-radius: 5px;
}

#no-kw-selected {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  color: rgb(32, 34, 44);
  background-color: rgba(180, 193, 251, 0.5);
  border-radius: 5px;
  margin-top: 20%;
}

/* HVALS  */

#hval-latest-score, #avg-hval-chart {
  border: 1px solid white;
  border-radius: 5px;
  justify-content: center;

}

#avg-hval-chart {
  margin: 0;
}

#avg-hval-chart>div {
  border: 1px solid white;
  border-radius: 5px;
  margin: 1%;
}

.hval-avg>div {
  font-family: 'Zen Dots', sans-serif;
  font-size: 2.7vw;
}

#hval-latest-score>div {
  font-size: 4.5vw;
  font-family: 'Zen Dots', sans-serif;
}

.red-score {
  background-color: rgba(231, 4, 0, 0.5);
  border: 1px solid rgb(231, 4, 0);
  color: rgb(250, 239, 19);
}
.yellow-score {
  background-color: rgba(231, 231, 0, .5);
  border: 1px solid rgb(232, 220, 0);
  color: rgb(251, 146, 1);
}
.green-score {
  background-color: rgba(19, 232, 0, .3);
  border: 1px solid rgb(1, 142, 1);
}

.blue-score {
  background-color: rgba(0, 93, 232, 0.4);
  border: 1px solid rgb(1, 109, 142);
}

.white-score {
  background-color: rgba(247, 247, 247, 0.7);
  border: 1px solid rgb(66, 66, 66);
  color: rgb(11, 11, 27)
}

/* TWEET FEED  */

#tweet-feed-wrapper {
  border: 1px solid white;
  border-radius: 5px;
  margin-top: 1%;
  max-height: 55vh;
  overflow-y: scroll;
}

#tweet-container {
  margin: 1%;
  justify-content: center;
}

.tweet {
  /* height: 10vh; */
  width: 35%;
  margin: 1% 1%;
  border: 1px solid white;
  border-radius: 3px;
  transition: background-color  .2s;
  font-size: .75rem;
}

a {
  text-decoration: none;
  color: rgb(181, 181, 183);
}

/* SPINNER FOR LOADING  */
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 2%;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}